import request from '@/utils/request'

// 查询门票用户领取记录列表
export function listTicket(query) {
  return request({
    url: '/icket/ticket/list',
    method: 'get',
    params: query
  })
}

// 查询门票用户领取记录详细
export function getTicket(id) {
  return request({
    url: '/icket/ticket/' + id,
    method: 'get'
  })
}

// 新增门票用户领取记录
export function addTicket(data) {
  return request({
    url: '/icket/ticket',
    method: 'post',
    data: data
  })
}

// 修改门票用户领取记录
export function updateTicket(data) {
  return request({
    url: '/icket/ticket',
    method: 'put',
    data: data
  })
}

// 删除门票用户领取记录
export function delTicket(id) {
  return request({
    url: '/icket/ticket/' + id,
    method: 'delete'
  })
}
