<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id 外键user_user.id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id 外键user_user.id" />
      </a-form-model-item>
      <a-form-model-item label="门票id 外键user_user.id" prop="ticketId" >
        <a-input v-model="form.ticketId" placeholder="请输入门票id 外键user_user.id" />
      </a-form-model-item>
      <a-form-model-item label="有效期开始" prop="validStart" >
        <a-date-picker style="width: 100%" v-model="form.validStart" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="有效期结束" prop="validEnd" >
        <a-date-picker style="width: 100%" v-model="form.validEnd" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="门票使用时间" prop="useTime" >
        <a-date-picker style="width: 100%" v-model="form.useTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="使用订单号" prop="useOrderId" >
        <a-input v-model="form.useOrderId" placeholder="请输入使用订单号" />
      </a-form-model-item>
      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
        <a-input v-model="form.version" placeholder="请输入乐观锁" />
      </a-form-model-item>
      <a-form-model-item label="使用状态，1：使用，0：未使用，默认0" prop="state" >
        <a-input v-model="form.state" placeholder="请输入使用状态，1：使用，0：未使用，默认0" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTicket, addTicket, updateTicket } from '@/api/icket/ticket'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        ticketId: null,

        validStart: null,

        validEnd: null,

        useTime: null,

        useOrderId: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        state: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id 外键user_user.id不能为空', trigger: 'blur' }
        ],

        ticketId: [
          { required: true, message: '门票id 外键user_user.id不能为空', trigger: 'blur' }
        ],

        validStart: [
          { required: true, message: '有效期开始不能为空', trigger: 'blur' }
        ],

        validEnd: [
          { required: true, message: '有效期结束不能为空', trigger: 'blur' }
        ],

        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],

        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ],

        state: [
          { required: true, message: '使用状态，1：使用，0：未使用，默认0不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        userId: null,

        ticketId: null,

        validStart: null,

        validEnd: null,

        useTime: null,

        useOrderId: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        state: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTicket(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTicket(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTicket(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
